import React from 'react'
import Subscribe from '@/templates/Subscribe'
import { useSlices } from '@/prismic/hooks/useSlices'
import { useSiteConfig } from '@/prismic/hooks/useSiteConfig'

const SubscribePrismic = () => {
  const { subscribe } = useSiteConfig()
  const blocks = useSlices([
    {
      slice_type: 'blog_posts',
      layout: 'Carousel',
      max_posts: 10,
      heading: { text: 'Latest news and insights' },
    },
  ])

  return <Subscribe {...subscribe} blocks={blocks} />
}

export default SubscribePrismic
