import React from 'react'
import PropTypes from 'prop-types'
import { Container } from '@/components/Container'
import { CampaignMonitorForm } from '@/components/CampaignMonitorForm'
import { BlockRenderer } from '@/components/BlockRenderer'
import * as styles from './Subscribe.module.scss'

const Subscribe = ({ blocks, heading, formId, submitLabel, emailInput }) => {
  return (
    <div className={styles.Subscribe}>
      <Container>
        <div className={styles.outer}>
          <div className={styles.inner}>
            <div className={styles.panel}>
              <h1 className={styles.heading}>{heading}</h1>
              {formId && (
                <CampaignMonitorForm
                  id={formId}
                  submitLabel={submitLabel}
                  hideName={true}
                  emailInput={emailInput}
                  customStyles={{
                    form: styles.form,
                    submit: styles.submit,
                    label: styles.label,
                    email: styles.email,
                    emailInput: styles.emailInput,
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </Container>
      {!!blocks?.length && (
        <div className={styles.blocks}>
          <BlockRenderer blocks={blocks} />
        </div>
      )}
    </div>
  )
}

Subscribe.propTypes = {
  blocks: PropTypes.arrayOf(PropTypes.object),
  heading: PropTypes.node,
  formId: PropTypes.string,
  submitLabel: PropTypes.string,
  emailInput: PropTypes.object,
}

export default Subscribe
