// extracted by mini-css-extract-plugin
export var Subscribe = "Subscribe-module--Subscribe--DkHll";
export var aniSpin = "Subscribe-module--ani-spin--cOzz5";
export var blocks = "Subscribe-module--blocks--VoE60";
export var email = "Subscribe-module--email--o4hLz";
export var emailInput = "Subscribe-module--emailInput--BDD9Q";
export var form = "Subscribe-module--form--b0gDS";
export var heading = "Subscribe-module--heading--+qaaW";
export var inner = "Subscribe-module--inner--sPC6J";
export var label = "Subscribe-module--label--PpWbs";
export var outer = "Subscribe-module--outer--aNC1o";
export var panel = "Subscribe-module--panel--0L1W0";
export var submit = "Subscribe-module--submit--Hp7gy";